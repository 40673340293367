import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import {Button, FormControl, Form, Modal} from 'react-bootstrap';
import { createSMSContact, listCountries } from "../../request"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SMSMailingForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            phoneNumber: "",
            country: null,
            countryCode: "NL",
            countries: [],
            honeyPot: null,
            tags: ["tag_newsletter"],
            hasPhoneNumberError: false,
            success: false,
            isProcessing: false,
            showModal: false,
            fieldsError: false,
            serverError: false,
            finished: false
        }
    }

    componentDidMount(){
        //Get a list of countries
        listCountries().then((results) => {

            let countries = [];

            let country = results.data.countries.find((c) => {
                return c.alpha2_code === this.state.countryCode;
            });

            results.data.countries.forEach((c) => {
                if (c.calling_codes && c.calling_codes.length === 1  && c.calling_codes[0] !== "" /* && !this.checkIfInList(countries, c)*/) {
                    c.calling_code = c.calling_codes[0];
                    countries.push(c);
                }
            });

            // countries.sort(function (a, b) {
            //     return a.calling_code - b.calling_code;
            // });

            this.setState({
                countries: countries,
                country: country
            });

        });
    }

    checkIfInList(countries, country){
        let output = false;
        countries.forEach((c) => {
            if (c.calling_code === country.calling_codes[0]) {
                output = true;
            }
        });

        return output;
    }

    fetchDefaultCountry = () => {

        let country = this.state.countries.find((c) => {
            return c.alpha2_code === this.state.countryCode;
        });

        return country;
    }

    handleCountryChange = (e) => {
        e.persist();

        let country = this.state.countries.find((c) => {
            return c.alpha2_code === e.target.value
        });

        this.setState({
            country: country,
            countryCode: country.alpha2_code
        });
    }

    handleInputChange = (e) => {
        if (e && e.target && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

        this.setState({
            hasPhoneNumberError: false
        });
    }

    handleHoneyPotChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            honeyPot: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isProcessing: true,
            finished: false,
            hasPhoneNumberError: false,
            fieldsError: false,
            serverError: false
        });

        if (!this.state.phoneNumber) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasPhoneNumberError: true
            });

        } else if (this.state.phoneNumber && this.state.phoneNumber.length > 12) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasPhoneNumberError: true
            });

        } else {

            if (!this.state.honeyPot) {

                let language = "en", region = "US";
                if (navigator && navigator.language) {
                    let navigatorLanguage = navigator.language.split("-");
                    if (navigatorLanguage && navigatorLanguage.length === 2){
                        language = navigatorLanguage[0].toLowerCase();
                        region = navigatorLanguage[1].toUpperCase();
                    }
                }

                createSMSContact({phone: `+${this.state.country.calling_code}${this.state.phoneNumber}`, tags: this.state.tags, language: language, region: region}).then(() => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        phoneNumber: "",
                        country: this.fetchDefaultCountry(),
                        countryCode: "NL",
                        finished: true,
                        serverError: false
                    });

                }).catch((e) => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        finished: false,
                        serverError: true
                    });

                });

            } else {

                this.setState({
                    isProcessing: false,
                    showModal: true,
                    finished: false,
                    serverError: true
                });

            }

        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    render() {
        return (
            <div className={`mailing-form`}>
                <div className={`title`}><FormattedMessage id="components.smsMailingForm.title" /></div>
                <div className={`text`} dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "components.smsMailingForm.text" }) }}></div>
                <div className={`form`}>
                    <Form className={`form-area`} onSubmit={this.handleSubmit}>
                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.smsMailingForm.email.label" /></Form.Label>
                            <div className={`phone-selector`}>
                                <div className={`select-container country-selector`}>
                                    {this.state.country &&
                                    <div className={`country-flag`}><img alt={this.state.country.name} src={this.state.country.flag} /></div>
                                    }
                                    {this.state.countries &&
                                    <FormControl as={`select`} value={this.state.countryCode} name={`country`} className="form-field" onChange={this.handleCountryChange}>
                                        {this.state.countries.map((country, i) => {
                                            return (
                                              <option key={i} value={country.alpha2_code}>(+{country.calling_code}) {country.name}</option>
                                            )
                                        })}
                                    </FormControl>
                                    }
                                </div>
                                <FormControl type="text" value={this.state.phoneNumber} name={`phoneNumber`} className="form-field phone-input" onChange={this.handleInputChange} />
                            </div>

                            <div style={{position: "absolute", left: "-9999px", overflow: "hidden"}} aria-hidden="true">
                                <input className="form-field" placeholder={this.props.intl.formatMessage({ id: "components.smsMailingForm.email.placeholder" })} type="text" onChange={this.handleHoneyPotChange} />
                            </div>

                            {this.state.hasPhoneNumberError === true &&
                            <>
                                <div className={`feedback-text error`}><FormattedMessage id="components.smsMailingForm.email.error" /></div>
                                <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                            </>
                            }
                        </div>
                        <Button className={`form-button`} variant="secondary" type="submit" block disabled={this.state.isProcessing}><FormattedMessage id="components.smsMailingForm.button" /></Button>
                    </Form>

                    <Modal show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.fieldsError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-triangle" />
                                    <FormattedMessage id="components.smsMailingForm.alerts.titles.warning" />
                                </>
                                }
                                {this.state.serverError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-circle" />
                                    <FormattedMessage id="components.smsMailingForm.alerts.titles.error" />
                                </>
                                }
                                {this.state.finished === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="check-circle" />
                                    <FormattedMessage id="components.smsMailingForm.alerts.titles.success" />
                                </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={`text-center m-3`}>
                                {this.state.fieldsError === true &&
                                <FormattedMessage id="components.smsMailingForm.alerts.error" />
                                }
                                {this.state.serverError === true &&
                                <FormattedMessage id="components.smsMailingForm.alerts.serverError" />
                                }
                                {this.state.finished === true &&
                                <FormattedMessage id="components.smsMailingForm.alerts.success" />
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default injectIntl(SMSMailingForm)
